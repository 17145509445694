import "../GuestForm/guestforminfocss.css"
import React, { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";

const GuestFormInfo = () => {

    const [openState, setOpen] = React.useState({
      open: false,
      vertical: "top",
      horizontal: "right",
    });
    const [emailValidation, setEmailValidation] = React.useState(null);
    const [apiStatus, setapiStatus] = React.useState(null);
    const [email, setEmail] = useState("");
    const [Bio, setBio] = useState("");
    const [Linkedin, setLinkedin] = useState("");
    const [Instagram, setInstagram] = useState("");
    const [Twitter, setTwitter] = useState("");
    const [SocialMediaTag, setSocialMediaTag] = useState("");
    const [TopicsToDiscuss, setTopicsToDiscuss] = useState("");
    const [ExternalTopics, setExternalTopics] = useState("");
    const [TopicsEmpOnPodcasts, setTopicsEmpOnPodcasts] = useState("");
    const [ExtraSocailMediaProfiles, setExtraSocailMediaProfiles] = useState("");
    
    
    const [clickedSubscribe, setClickedSubscribe] = React.useState(false);
    //const Alert = React.forwardRef(function Alert(props, ref) {
      //return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    //});
  
   
   
  
    const sendEmailFunc = () => {
      const URL =
        "https://4kgtzn0cv6.execute-api.us-east-1.amazonaws.com/dev"
      const data = {
        email: email,
        Bio: Bio,
  Linkedin: Linkedin,
  Instagram: Instagram,
  Twitter: Twitter,
  SocialMediaTag: SocialMediaTag,
  TopicsToDiscuss: TopicsToDiscuss,
  TopicsEmpOnPodcasts: TopicsEmpOnPodcasts,
  ExternalTopics: ExternalTopics,
  ExtraSocailMediaProfiles : ExtraSocailMediaProfiles,
      };
      axios
        .post(URL, data)
        .then(function (response) {
          if (response.status === 200) {
            setapiStatus(true);
          } else {
            setapiStatus(false);
          }
        })
        .catch(function (error) {
          setapiStatus(false);
        });
    };
  
    const emailValidator = (email) => {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email.match(validRegex)) {

          setEmailValidation(true);
          sendEmailFunc();
          setEmail("");
          setBio("");
          setInstagram("");
          setLinkedin("");
          setTwitter("");
          setExtraSocailMediaProfiles("");
          setSocialMediaTag("");
          setTopicsToDiscuss("");
          setTopicsEmpOnPodcasts("");
          setExternalTopics("");

          alert("Thanks for your Feedback!!");
        } else {
    
          setEmailValidation(false);
          alert("Please Enter valid email !");
        }
        setOpen({ ...openState, open: true });
    };
  
   const submitClickHandler = (e) => {
    e.preventDefault();
     emailValidator(email);
    };
  
 return (
    
    
    <div class="wrapper">
    <div class="form-container">        
        <form class="feedback-form" onSubmit={submitClickHandler}>

            <span class="feedback-heading">
                Thank you, My Guest! <br/>
                Please fill the form with following details.<br/>
                This info helps me to increase the quality and reachability of the podcast.
            </span>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Email*</h2></b></span> 
    <input type="text" placeholder="Please enter your email" className="Email-text" value={email} required={true} onChange={(e) => {
              e.preventDefault();
              setEmail(e.target.value);
            }} />
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2> Can you please provide a short Bio?</h2></b></span> 
    <textarea rows="4" cols="50" class="Email-text"  placeholder="Please provide your Bio" value={Bio} onChange = {(e) =>
    {
      e.preventDefault();
              setBio(e.target.value);
    }} ></textarea>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Photo (Preferably Headshot or anything that goes well on the video)</h2></b></span> 
    <input type="file"  placeholder="Upload Image"/>  
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Instagram</h2></b></span> 
    <input type="text" placeholder="Please enter your Instagram account" class="Email-text"  value={Instagram} onChange = {(e) =>
    {
      e.preventDefault();
              setInstagram(e.target.value);
    }}/>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Linkedin</h2></b></span> 
    <input type="text" placeholder="Please enter your Linkedin account" class="Email-text"    value={Linkedin} onChange = {(e) =>
    {
      e.preventDefault();
              setLinkedin(e.target.value);
    }}/>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Twitter</h2></b></span> 
    <input type="text" placeholder="Please enter your Twitter account" class="Email-text"    value={Twitter} onChange = {(e) =>
    {
      e.preventDefault();
              setTwitter(e.target.value);
    }} />
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2> Please add social media profiles</h2></b></span> 
    <textarea rows="4" cols="50" class="Email-text"  placeholder="Please add other social media profiles that you have."  value={ExtraSocailMediaProfiles} onChange = {(e) =>
    {
      e.preventDefault();
              setExtraSocailMediaProfiles(e.target.value);
    }}></textarea>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2> Is it okay if I tag your social media profile while I publish articles to reach wider audience</h2></b></span> 
    <textarea rows="4" cols="50" class="Email-text"   placeholder="Yes/No"  value={SocialMediaTag} onChange = {(e) =>
    {
      e.preventDefault();
              setSocialMediaTag(e.target.value);
    }} ></textarea>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>  What topics would you like to chat with me on the podcast? (Other than what I already specified offline)</h2></b></span> 
    <textarea rows="4" cols="50" class="Email-text"   placeholder="Please mention some topics that you want to talk about"  value={TopicsToDiscuss} onChange = {(e) =>
    {
      e.preventDefault();
              setTopicsToDiscuss(e.target.value);
    }} ></textarea>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2> Do you want me to emphasize on any specific topic during podcast?</h2></b></span> 
    <textarea rows="4" cols="50" class="Email-text"   placeholder="Please mention some topics you want to emphasize on podcast"  value={TopicsEmpOnPodcasts} onChange = {(e) =>
    {
      e.preventDefault();
              setTopicsEmpOnPodcasts(e.target.value);
    }}></textarea>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2> Please add any external info/notes that I should be aware of.</h2></b></span> 
    <textarea rows="4" cols="50" class="Email-text"   placeholder="Please add external topics"  value={ExternalTopics} onChange = {(e) =>
    {
      e.preventDefault();
              setExternalTopics(e.target.value);
    }}></textarea>
</div>
<div class="btn-container">
<button class="btn-guest" > 
    <span>
        Submit 
    </span></button>
</div>
           </form>
           </div>
           </div>
 );
};

export default GuestFormInfo;