import { useEffect } from 'react';

const MediaSearch = () => {
  useEffect(() => {
    window.location.href = 'https://main.d3h1qv791qkjbu.amplifyapp.com/';
  }, []);

  return (
    <div>
      
    </div>
  );
};

export default MediaSearch;
