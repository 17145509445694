import React from "react";
import "./getIntouchStyles.css";

import getIntouch from "../../assests/getIntouch.svg";

const GetInTouchSection = () => {
  return (
    <section className="getintouch">
      <div>
        <img
          src={getIntouch}
          alt="getIntouch"
          className="getintouch__image"
        ></img>
      </div>
      <div className="getintouch__content">
        <div className="getintouch__content__description">
          We can always do more Together.
        </div>
        <div className="getintouch__content__minidescription">
          <div>Open to collaborate. </div>
          <div className="getintouch__content__minidescription--text">
            Don't forget to follow and subscribe!
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouchSection;
