import HomePage from "./Pages/HomePage/HomePage";
import VideoPlayerPage from "./Pages/VideoPlayerPage/VideoPlayerPage";
import { Route, Routes } from "react-router-dom";
import GuestFormInfo from "./Pages/GuestForm/GuestFormInfo";
import EpisodesForm from "./Pages/EpisodesDynamoForm/EpisodesForm"
import EpisodesFormEdit from "./Pages/EpisodesDynamoForm/EpisodesFormEdit"
import MediaSearch from "./Pages/MediaSearchPage/MediaSearch"


function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<HomePage></HomePage>}></Route>
        <Route
          exact
          path="/videoPlayer/:id"
          element={<VideoPlayerPage />}
        ></Route>
        <Route
          exact
          path="/guestinfo"
          element={<GuestFormInfo/>}
        ></Route>
        <Route
          exact
          path="/episodeinfo"
          element={<EpisodesForm/>}
        ></Route>
        <Route
          exact
          path="/episodeinfoedit"
          element={<EpisodesFormEdit/>}
        ></Route>
        <Route
          exact
          path="/searchbar"
          element={<MediaSearch/>}
        />
      </Routes>
    </div>
  );
}

export default App;
