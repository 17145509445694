import React, { useState } from "react";
import { Link } from "react-scroll";
import navbarIcon from "../assests/navbaricon.png";
import "./navbarStyles.css";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
// import { grey } from "@mui/material/colors";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "black",
  marginLeft: "1rem",
  "&:hover": {
    backgroundColor: "green",
  },
}));

// const RecentEpisodesButton = styled(Button)(({ theme }) => ({
//   color: "black",
//   backgroundColor: "#F1F1EF",
//   border: "1px solid black",
//   fontFamily: "Poppins",
//   fontWeight: "700",
//   fontSize: "14.6759px",
//   "&:hover": {
//     backgroundColor: "lightgray",
//   },
// }));
const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [emailValidation, setEmailValidation] = React.useState(null);
  const [apiStatus, setapiStatus] = React.useState(null);
  const [email, setEmail] = useState("");
  const [clickedSubscribe, setClickedSubscribe] = React.useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSnackBar = (event, reason) => {
    setEmailValidation((prevemailValidation) => !prevemailValidation);
    setapiStatus((prevApistatus) => !prevApistatus);
    if (reason === "clickaway") {
      return;
    }
    setOpen({ ...openState, open: false });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const subscribeClickHandler = () => {
    setClickedSubscribe((prevState) => !prevState);
  };

  const sendEmailFunc = () => {
    const URL =
      "https://eg6guxz6s2.execute-api.us-east-1.amazonaws.com/dev/boawithrohithemail"
    const data = {
      email: email,
    };
    axios
      .post(URL, data)
      .then(function (response) {
        if (response.status === 200) {
          setapiStatus(true);
        } else {
          setapiStatus(false);
        }
      })
      .catch(function (error) {
        setapiStatus(false);
      });
  };

  const emailValidator = (email) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {

      setEmailValidation(true);
      sendEmailFunc();
      subscribeClickHandler();
      setEmail("");
    } else {

      setEmailValidation(false);
    }
    setOpen({ ...openState, open: true });
  };

  const submitClickHandler = () => {
    emailValidator(email);
  };
  return (
    <nav className="navbar">
      <Snackbar
        open={openState.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: openState.vertical,
          horizontal: openState.horizontal,
        }}
      >
        {!emailValidation ? (
          <Alert
            onClose={handleCloseSnackBar}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Please enter valid email !
          </Alert>
        ) : emailValidation && apiStatus ? (
          <Alert
            onClose={handleCloseSnackBar}
            severity="success"
            sx={{ width: "100%" }}
          >
            We will get back to you soon :)
          </Alert>
        ) : (
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            sx={{ width: "100%" }}
          >
            Something went wrong please try again later :(
          </Alert>
        )}
      </Snackbar>

      <div className="navbar__left">
        <img
          src={navbarIcon}
          alt="navbar-icon"
          className="navbar__left--image"
        />
      </div>
      <div className="navbar__middle"></div>

      <div className="navbar__right">
        <Link
          className="navbar__right__link"
          to="episodes"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
        >
          Episodes
        </Link>
        <Link
          className="navbar__right__link"
          to="about"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
        >
          About
        </Link>

        {/*<RecentEpisodesButton>Recent Episodes</RecentEpisodesButton>*/}
        {/*<Button variant="outlined">Recent Episodes</Button>*/}
        <ColorButton
          variant="contained"
          onClick={subscribeClickHandler}
          className={
            clickedSubscribe
              ? "navbar__sendsubscribe__hidden"
              : "navbar__sendsubscribe__visible"
          }
        >
          Subscribe
        </ColorButton>
        <Paper
          className={
            clickedSubscribe
              ? "navbar__sendsubscribe__visible"
              : "navbar__sendsubscribe__hidden"
          }
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 300,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Email"
            inputProps={{ "aria-label": "Email" }}
            value={email}
            onChange={(e) => {
              e.preventDefault();
              setEmail(e.target.value);
            }}
          />
          <ColorButton
            variant="contained"
            onClick={submitClickHandler}
            onChange={(e) => {
              e.preventDefault();
            }}
          >
            Subscribe
          </ColorButton>
        </Paper>
      </div>
      <div className="navbar__popupmenu">
        <MenuIcon
          onClick={handleClick}
          style={{
            cursor: "pointer",
          }}
        ></MenuIcon>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            Episodes
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            About
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <ColorButton variant="contained">Subscribe</ColorButton>
          </MenuItem>
        </Menu>
      </div>
    </nav>
  );
};

export default Navbar;
