import React from "react";
import { Link } from "react-router-dom";
import "./commonStyles.css";
import { useState, useEffect } from 'react';
import axios from 'axios';

const EpisodeCard = ({ info, index }) => {
  const [data, setData] = useState(null);

  

  console.log(data);

  return (
    <Link to={`/videoPlayer/${info.epno}`} state={info}>
      <section className="recentepisodes__card">
        <div
          style={{ display: "flex", flexWrap: "wrap", alignContent: "center" }}
        >
          <img
            src={info.image}
            alt="recentepisode"
            className="recentepisodes__card--image" width="174" height="174"
          ></img>
        </div>
        <div className="recentepisodes__card__content">
          <div className="recentepisodes__card__content--container">
            <div className="recentepisodes__card__content--episode">
              Eps.{info.epno}
            </div>
            <div className="recentepisodes__card__content--heading">
              {info.eptitle}
            </div>
          </div>

          <div className="recentepisodes__card__content--description">
            {info.eptext}
          </div>
        </div>
      </section>
    </Link>
  );
};

export default EpisodeCard;
