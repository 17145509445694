import React from "react";
import { Element } from "react-scroll";
import "./aboutSection.css";
import AboutSectionCard from "../../Common/AboutSectionCard";
import sports from "../../assests/sports.svg";
import culture from "../../assests/cukture.svg";
import startup from "../../assests/startup.svg";
import technology from "../../assests/technology.svg";
import carrer from "../../assests/carrer.svg";
import FooterCard from "../../Common/FooterCard";
import Grid from "@mui/material/Grid";

const AboutSection = () => {
  return (
    <Element>
      <section className="aboutSection" id="about">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="aboutSection__heading">
              A place to Learn and Grow together
            </div>
          </Grid>

          <Grid item xs={10}>
            <AboutSectionCard
              cardImage={startup}
              frontText={"Startups"}
              backText={
                "I will talk to founders, CEOs, CTOs, VCs, and people in the ecosystem to break down the journey, learnings, and wisdom so that we all can learn together"
              }
              index={1}
            ></AboutSectionCard>

            <AboutSectionCard
              cardImage={technology}
              frontText={"Technology"}
              backText={
                "I will create more content in getting into tech, preparing for interviews, growth and career transitions, investing and more- let's learn together :)"
              }
              index={2}
            ></AboutSectionCard>

            <AboutSectionCard
              cardImage={culture}
              frontText={"Diversity"}
              backText={
                "Let us be honest- we need to learn a lot and have hard conversations in this space to break our biases. I will do so!"
              }
              index={4}
            ></AboutSectionCard>

            <AboutSectionCard
              cardImage={sports}
              frontText={"Sports"}
              backText={
                "I'm very passionate about sports and I believe there's quite a bit to learn from the pro players in every aspect of life. "
              }
              index={5}
            ></AboutSectionCard>
            <AboutSectionCard
              cardImage={carrer}
              frontText={"Open Talks"}
              backText={
                "I don't really want to limit myself to any topic so I will try to cover any topic that excites me and has great value for listners- so stay tuned :)"
              }
              index={3}
            ></AboutSectionCard>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "24%" }}>
            <FooterCard></FooterCard>
          </Grid>
        </Grid>
      </section>
    </Element>
  );
};

export default AboutSection;
