import { useEffect } from "react";
import "./Medium.css";

//   mediumPromise.then(function () {
//     //Pagination
//     var pageSize = 10;

//     var pageCount = $(".card").length / pageSize;

//     for (var i = 0; i < pageCount; i++) {
//       $("#pagin").append(
//         `<li class="page-item"><a class="page-link" href="#">${i + 1}</a></li> `
//       );
//     }
//     $("#pagin li:nth-child(1)").addClass("active");

//     const showPage = (page) => {
//       console.log(page);
//       $(".card").hide();
//       $(".card").each(function (n) {
//         if (n >= pageSize * (page - 1) && n < pageSize * page) $(this).show();
//       });
//     };

//     $("#pagin li").click(function () {
//       $("#pagin li").removeClass("active");
//       $(this).addClass("active");
//       showPage(parseInt($(this).text()));
//       return false;
//     });
//   });
// });

const MediumIntegration = () => {
  useEffect(() => {
    const mediumFunc = async () => {
      var data = {
        rss: "https://medium.com/feed/@boawithrohith",
      };
      const jsonData = await fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40boawithrohith",
        data
      );
      const response = await jsonData.json();
      console.log(response);
      if (response.status === "ok") {
        let htmlArr = response.items.map((item) => {
          var display = "";
          display += `<div class="card-medium mb-3 mx-auto mr-5 " style="width:30rem;">`;
          //var src = item["thumbnail"]; // use thumbnail url
          //display += `<img src="${src}" class="card-img-top" alt="Cover image">`;
          display += `<div class="card-body">`;
          display += `<h4><a class="Link" href="${item.link}">${item.title}</a></h4>`;
          var yourString = item.description.replace(/<img[^>]*>/g, ""); //replace with your string.
          yourString = yourString.replace("h6", "p");
          yourString = yourString.replace("h5", "p");
          var maxLength = 120; // maximum number of characters to extract
          //trim the string to the maximum length
          var trimmedString = yourString.substr(0, maxLength);
          //re-trim if we are in the middle of a word
          trimmedString = trimmedString.substr(
            0,
            Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
          );
          display += `<p class="LinkText">${trimmedString}...</p>`;

          display += `<a href="${item.link}" target="_blank" class="btn btn-outline-success" >Read More</a>`;
          return display;
        });
        console.log(htmlArr);
        const domEl = document.getElementById("jsonContent");
        console.log(domEl);
        //!remove the html before adding
        // domEl.innerHTML = "";

        while (domEl.firstChild) {
          domEl.removeChild(domEl.lastChild);
        }
        for (let i = 0; i < htmlArr.length; i++) {
          domEl.insertAdjacentHTML("afterbegin", htmlArr[i]);
        }
      }
    };
    mediumFunc();
  }, []);
  return (
    <div className="container mt-5 pt-5" id="medium">
      <div className="jumbotron text-center">
        <div id="logo"></div>
        <h1 className="recentepisodes__heading_blog">Blog Posts</h1>
      </div>
      <div className="row" id="jsonContent"></div>
    </div>
  );
};
export default MediumIntegration;
