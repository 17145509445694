import React from "react";
import rohithImage from "../assests/rohithImageAboutSec.svg";
import crown from "../assests/crown.svg";
import "./commonStyles.css";
const FooterCard = () => {
  return (
    <>
      <div className="aboutsection__footer">
        <div style={{ position: "relative" }}>
          <img
            className="aboutsection__footer--crown"
            src={crown}
            alt="crown"
          />
          <img
            className="aboutsection__footer--image"
            src={rohithImage}
            alt="rohithImage"
          />
        </div>
        <div className="aboutsection__footer--text">
          The common goal of each epsiode is to present the journeys of people
          (our everyday heroes?), uncover the life lessons, learnings, mistakes
          and present in a way where we all can learn together all while having
          fun.
        </div>
      </div>
    </>
  );
};

export default FooterCard;
