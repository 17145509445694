import React,{ useState } from "react";
import "./homepageFooterStyle.css";
import Grid from "@mui/material/Grid";
import footerIcon from "../../assests/navbaricon.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import axios from "axios";
import { Facebook } from "@mui/icons-material";
import tiktokicon from "../../assests/tiktok.png"
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
// import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";

const HomePageFooter = () => {
  const [email, setEmail] = useState("");
  const [openState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [emailValidation, setEmailValidation] = React.useState(null);
  const [apiStatus, setapiStatus] = React.useState(null);
  const sendEmailFunc = () => {
    const URL =
      "https://eg6guxz6s2.execute-api.us-east-1.amazonaws.com/dev/boawithrohithemail"
    const data = {
      email: email,
    };
    axios
      .post(URL, data)
      .then(function (response) {
        if (response.status === 200) {
          setapiStatus(true);
        } else {
          setapiStatus(false);
        }
      })
      .catch(function (error) {
        setapiStatus(false);
      });
  };

  const emailValidator = (email) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {

      setEmailValidation(true);
      sendEmailFunc();
      setEmail("");
    } else {

      setEmailValidation(false);
    }
    setOpen({ ...openState, open: true });

  };

  const submitClickHandler = () => {
    emailValidator(email);
  };
  
  return (
    <div className="footer__container">
      <Grid container direction={"row"} justifyContent="space-around">
        <Grid
          item
          container
          xs={4}
          md={5}
          xl={6}
          justifyContent="space-between"
        >
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <img
              src={footerIcon}
              alt="footerIcon"
              className="footer__icon"
            ></img>
          </Grid>
          <Grid>
            
          </Grid>
          <Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <InstagramIcon></InstagramIcon>
              <a
                href="https://www.instagram.com/boawithrohith/"
                className="footer__link"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </div>
          </Grid>
          <Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <LinkedInIcon></LinkedInIcon>
              <a href="https://www.linkedin.com/company/boawithrohith/" className="footer__link">
                Linkedin
              </a>
            </div>
          </Grid>
          <Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <TwitterIcon></TwitterIcon>
              <a href="https://twitter.com/BOAWithRohith" className="footer__link">
                Twitter
              </a>
            </div>
          </Grid>
          <Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Facebook></Facebook>
              <a href="https://www.facebook.com/people/Boawithrohith/100088276730106/" className="footer__link">
                Facebook
              </a>
            </div>
          </Grid>
          <Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <img src={tiktokicon} width='20px'height='20px'></img>
              <a href="https://www.tiktok.com/@boawithrohith" className="footer__link">
              TiKTok
              </a>
            </div>
          </Grid>
          <Grid xs={12} md={12} xl={12} style={{ marginTop: "2rem" }}>
            © 2022 Boa with Rohith All rights reserved
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          justifyContent="space-evenly"
          xs={4}
          md={1}
          xl={1}
        >
          <Grid>Stay up to date</Grid>
          <Grid>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 150,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Email"
                inputProps={{ "aria-label": "Email" }}
                value={email}
            onChange={(e) => {
              e.preventDefault();
              setEmail(e.target.value);
            }}

              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={submitClickHandler} onChange={(e) => {
              e.preventDefault();
              setEmail("");

            }}>
                <SendIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePageFooter;
