import React, { useState, useRef } from "react";
import { Component } from "react";
import axios from "axios";
import "../EpisodesDynamoForm/episodesformcss.css"
const EpisodesForm = () => {
  const formRef = useRef(null);
     const [epno, setEpno] = useState("");
    const [eptitle, setEptitle] = useState("");
    const [eptext, setEptext] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [spotify, setSpotify] = useState("");
    const [apple, setApple] = useState("");
    const [youtube, setYoutube] = useState("");
    const [file,setFile] = useState("");
  const [presignedUrl, setPresignedUrl] = useState(null);
  const [fields, setFields] = useState({});
  const [password,setPassword] = useState("");
  


    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
      fetch('https://d9iszeif2h.execute-api.us-east-1.amazonaws.com/dev/myapi').then(function (response) {
        return response.json();
      }).then(data => {
        
        data=JSON.parse(data);
        setPresignedUrl(data['uploadURL']);
        setFields(data['Key']);
            })
      .catch(function (error) {
        console.log(error);
      });
    };
    
  
    const sendEmailFunc = async(e)=> {
      
    const binaryData = await getBinaryData(file);
    
       
      try {
        const response = await fetch(presignedUrl, {
          method: 'PUT',
          'Content-Type': 'image/png',
          body: binaryData,
        });

        if (response.ok) {
          console.log('File uploaded successfully');
        } else {
          console.error('Failed to upload file');
        }
      } catch (error) {
        console.error(error);
      }
     const data = JSON.stringify({
        password:password,
        fileName:fields,
        epno:epno,
          eptitle: eptitle,
          eptext:eptext,
          linkedin: linkedin,
          spotify:spotify,
          apple:apple,
          youtube:youtube,
    })
    const URL =
    "https://dbd8rjsjzh.execute-api.us-east-1.amazonaws.com/dev/episodeinfo"
    
  fetch(URL, {
    mode: "cors",
    method: 'post',
    body: data
  }).then(response =>{
      console.log('uploaded data');
      console.log(response);
      
      if(response.status && response.status===200)
    {
      
      setPassword("");
    setEpno("");
    setEptitle("");
    setEptext("");
    setLinkedin("");
    setSpotify("");
    setApple("");
    setYoutube("");
    formRef.current.reset();
    }
    else 
    {
        alert("Enter correct password")
    }
    })
    .catch(function (error) {
      console.log('error in uploading in data');
    });

        
      }

      function getBinaryData(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = () => {
            const binaryData = new Uint8Array(reader.result);
            resolve(binaryData);
          };
      
          reader.onerror = reject;
      
          reader.readAsArrayBuffer(file);
        });
      }
      
      
      
      
      
      
        
        



  

    
  
    
  
   const submitClickHandler = (e) => {
    e.preventDefault();
    sendEmailFunc(e);
    
    };
  
 return (
    
    
    <div class="wrapper">
    <div class="form-container">        
        <form class="feedback-form" onSubmit={submitClickHandler} ref={formRef}>
            <div  className="feedback-heading">
            

           Episode Information  
            </div>
            <div class="Email-text-container">
    <span class="Email-text-heading"><b><h2> Password*</h2></b></span> 
    <input  type="text" class="Email-text"  placeholder="Provide password" value={password} onChange = {(e) =>
    {
      e.preventDefault();
              setPassword(e.target.value);
    }} ></input>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Episode no*</h2></b></span> 
    <input type="text" placeholder="Provide Episode no" className="Email-text" value={epno} required={true} onChange={(e) => {
              e.preventDefault();
              setEpno(e.target.value);
            }} />
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2> Episode title*</h2></b></span> 
    <textarea rows="4" cols="50" class="Email-text" required={true} placeholder="Provide Episode title" value={eptitle} onChange = {(e) =>
    {
      e.preventDefault();
              setEptitle(e.target.value);
    }} ></textarea>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2> Episode text*</h2></b></span> 
    <textarea rows="4" cols="50" class="Email-text" required={true} placeholder="Provide Episode text" value={eptext} onChange = {(e) =>
    {
      e.preventDefault();
              setEptext(e.target.value);
    }} ></textarea>
</div>

<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Spotify</h2></b></span> 
    <input type="text" placeholder="Provide Spotify link" class="Email-text"  value={spotify} onChange = {(e) =>
    {
      e.preventDefault();
              setSpotify(e.target.value);
    }}/>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Linkedin</h2></b></span> 
    <input type="text" placeholder="Provide Linkedin link" class="Email-text"    value={linkedin} onChange = {(e) =>
    {
      e.preventDefault();
              setLinkedin(e.target.value);
    }}/>
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Apple</h2></b></span> 
    <input type="text" placeholder="Provide Apple link" class="Email-text"    value={apple} onChange = {(e) =>
    {
      e.preventDefault();
              setApple(e.target.value);
    }} />
</div>
<div class="Email-text-container">
    <span class="Email-text-heading"><b><h2>Youtube</h2></b></span> 
    <input type="text" placeholder="Provide Youtube link" class="Email-text" value={youtube} onChange = {(e) =>
    {
      e.preventDefault();
              setYoutube(e.target.value);
    }} />
</div>
<div class="Email-text-container">
      <span class="Email-text-heading"><b><h2>Episode Image*</h2></b></span>
      <input type="file" required={true} onChange = {handleFileChange}/>
</div>



<div class="btn-container">
<button class="btn-guest" > 
    <span>
        Submit 
    </span></button>
</div>
           </form>
           </div>
           </div>
 );
    };

  

    

export default EpisodesForm;
