import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import "./videoPlayercontentStyles.css";
import Spotify from "../../assests/Spotify.svg";
import ApplePodcast from "../../assests/Apple Podcasts.svg";

const VideoPlayerContent = ({data}) => {
  const [eptitle, setEpTitle] = useState("");
  const [eptext, setEpText] = useState("");
  const [spotify, setSpotify] = useState("");
  const [apple, setApple] = useState("");
  useEffect(() => {
    
    setEpTitle(data.eptitle);
    setEpText(data.eptext);
    setSpotify(data.spotify);
    setApple(data.apple);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 return (
    <div className="videoplayercontent">
      <div className="videoplayercontent__heading">
        {eptitle}
      </div>
      <div className="videoplayercontent__description">
        {eptext}
      </div>
      <div className="videoplayercontent__available__container">
        <div className="videoplayercontent__available">
          Episode also available on
        </div>
        <a
          href={`https://open.spotify.com/episode/${spotify}`}
          className="homepage__content__social--content"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Spotify} alt="spotify"></img>
          <span className="homepage__content__social--content--text">
            Spotify
          </span>
        </a>
        <br></br>
        <a href={`https://podcasts.apple.com/us/podcast/${apple}`}
                    className="homepage__content__social--content"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={ApplePodcast} alt="ApplePodcast"></img>
                    <span className="homepage__content__social--content--text">
                      Apple Podcast
                    </span>
                  </a>
        
      </div> 
      
      
    </div>
  );
};

export default VideoPlayerContent;
