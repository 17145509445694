import React from "react";
import Navbar from "../../Components/Navbar";
import AboutSection from "./AboutSection";
import RecentEpisodesSection from "./RecentEpisodesSection";
import GetInTouchSection from "./GetInTouchSection";
import InvestorsSection from "./InvestorsSection";
import HomePageFooter from "./HomePageFooter";
import Grid from "@mui/material/Grid";
import rohithImage from "../../assests/rohithImage.svg";
import "./homepageStyles.css";
import Spotify from "../../assests/Spotify.svg";
import youtube from "../../assests/youtube.svg";
import ApplePodcast from "../../assests/Apple Podcasts.svg";
import MediumIntegration from "./MediumIntegration";
const HomePage = () => {
  return (
    <>
   
   <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
    <div className="homepage">
        <div className="homepage__container">
          <Navbar></Navbar>
          <section className="homepage__content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <span className="homepage__text">
                 <em> Be Open & Authentic  </em>
                  <span className="homepage__subtext"> <em> With Rohith </em></span>
                </span>
                <div className="homepage__content__social">
                  <a
                    href="https://open.spotify.com/show/4gVe24tQWcvtOHhIMBjQlo"
                    className="homepage__content__social--content"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Spotify} alt="spotify"></img>
                    <span className="homepage__content__social--content--text">
                      Spotify
                    </span>
                  </a>
                  <a
                    href="https://www.youtube.com/@boawithrohith"
                    className="homepage__content__social--content"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={youtube} alt="youtube"></img>
                    <span className="homepage__content__social--content--text">
                      Youtube
                    </span>
                  </a>
                  {<a
                    href="https://podcasts.apple.com/us/podcast/boawithrohith/id1663544623"
                    className="homepage__content__social--content"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={ApplePodcast} alt="ApplePodcast"></img>
                    <span className="homepage__content__social--content--text">
                      Apple
                    </span>
                  </a> }
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  style={{ width: "100%" }}
                  src={rohithImage}
                  alt="rohithImage"
                  id="homePage__Image"
                ></img>
              </Grid>
            </Grid>
          </section>
        </div>
        <AboutSection></AboutSection>
        <RecentEpisodesSection></RecentEpisodesSection>
        <MediumIntegration></MediumIntegration>
        <GetInTouchSection></GetInTouchSection>
        <InvestorsSection></InvestorsSection>
                    
      </div>
      <HomePageFooter></HomePageFooter>
      
    </>
  );
};

export default HomePage;
