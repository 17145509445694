import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./videoPlayerPage.css";
import { useParams } from "react-router-dom";
import VideoPlayerContent from "./VideoPlayerContent";
import sidearrow from "../../assests/sidearrow.svg";
import axios from "axios";
const VideoPlayerPage = () => {
  
  const [data, setData] = useState(null);
  const [youtube,setYoutube]=useState(null);
const pepno=useParams();
  useEffect(() => {
    axios.get(`https://798lxjxgek.execute-api.us-east-1.amazonaws.com/video/myapi?epno=${pepno.id}`)
      .then(response =>{
       
       const Array=response.data;
       console.log(Array);
        setData(Array);
        setYoutube(Array.youtube);
        })
      .catch(error => console.error(error))
  }, []);

  
 

    
  
  
  
 

 return(
    <>
      <section className="videoplayerpage">
        <Link to="/">
          <div className="videoplayerpage__backbuttonconatiner">
            <img src={sidearrow} alt="sidearrow"></img>
            <div className="videoplayerpage__backbutton">Back to Home</div>
          </div>
        </Link>

        <div className="videoplayer">
          {youtube && (
            <iframe
              className="videoplayer__iframe"
              title="video"
              width="100%"
              src={`https://www.youtube.com/embed/${youtube}`}
            ></iframe>
          )}
        </div>
      </section>
      {data && <VideoPlayerContent data={data}></VideoPlayerContent>}
      
    </>
  );
};

export default VideoPlayerPage;
