import React from "react";
import "./aboutSectionCardStyles.css";

const AboutSectionCard = ({ cardImage, frontText, backText, index }) => {
  return (
    <div className={`scene scene--card aboutSection__${index}`}>
      <div className="card">
        <div className="card__face card__face--front">
          <img src={cardImage} alt="frontimage" className="cardImage"></img>
        </div>
        <div className="frontText">{frontText}</div>
        <div className="card__face card__face--back">
          <img src={cardImage} alt="backimage" className="cardImage" />
        </div>
        <div className="backText">{backText}</div>
      </div>
    </div>
  );
};

export default AboutSectionCard;
