import React from "react";
import "./investorSectionStyles.css";
import cruxito from "../../assests/CRUXITO.PNG";
import brandbar from "../../assests/brandbar.png";

const InvestorsSection = () => {
  return (
    <section className="investorsection">
      <div className="investorsection__heading">Our Partners</div>
      <div className="investorsection__content">
        <a href="https://www.thebrandbar.com.au/" target="_blank" rel="noreferrer">
          <img src={brandbar} alt="ms" width='200' height='50'></img>
        </a>&nbsp; &nbsp;
        <a href="https://www.cruxito.tech/" target="_blank" rel="noreferrer">
          <img src={cruxito} alt="amazon"></img>
        </a>
      </div>
    </section>
  );
};

export default InvestorsSection;
