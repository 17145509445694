import React from "react";
import { Element } from "react-scroll";
import EpisodeCard from "../../Common/EpisodeCard";
import "./recentEpisodesStyle.css";

import { useState, useEffect } from 'react';
import axios from 'axios';



        const RecentEpisodesSection = () => {
          
          const [data, setData] = useState(null);

          useEffect(() => {
            axios.get('https://dbd8rjsjzh.execute-api.us-east-1.amazonaws.com/dev/episode')
              .then(response =>{
                console.log(response.data,"     hi") 
                setData(response.data)
                })
              .catch(error => console.error(error))
          }, []);

          console.log(data);
  

  

  return (
    <Element className="recentepisodes">
      <section id="episodes">
        <div className="recentepisodes__heading">Recent Episodes</div>
        
        <div className="recentepisodes__content__container">
          <div className="recentepisodes__content">
            {data &&
              data.length &&
              data.map((el, index) => (
                <EpisodeCard info={el} index={index}></EpisodeCard>
              ))}
          </div>
        </div>
      </section>
      
    </Element>
  );
};

export default RecentEpisodesSection;
